import React, { useContext } from 'react'
import { getVideosByIds, randomizeVideos } from '../suggestedVideoHelpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'

const computeAllLodgingVideos = (allLodgingReviews, allVideos) => {
  return randomizeVideos(
    getVideosByIds(
      allLodgingReviews.map(video => video.videoId),
      allVideos
    )
  ).slice(0, 3)
}

const AllLodgingVideos = () => {
  const allVideos = useContext(VideoContext)
  const allLodgingReviews = useContext(LodgingContext)

  return (
    <SuggestedVideos
      videos={computeAllLodgingVideos(allLodgingReviews, allVideos)}
    />
  )
}

export default AllLodgingVideos
