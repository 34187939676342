import React, { useContext, useRef, useState } from 'react'
import AllLodgingVideos from '../components/SuggestedVideos/AllLodgingVideos/AllLodgingVideos'
import BlogPost from '../components/blogPost/BlogPost'
import CategorySelector from '../components/Selectors/CategorySelector/CategorySelector'
import { computeUniqueCategories } from '../helpers/helpers'
import { getFluidImage } from '../helpers/dynamicGatsbyImage'
import { graphql } from 'gatsby'
import LodgingContext, {
  LodgingData,
} from '../contexts/lodgingData/lodgingData'
import LodgingVideosByState from '../components/SuggestedVideos/LodgingVideosByState/LodgingVideosByState'
import MediaQuery from '../components/sharedComponents/MediaQuery/MediaQuery'
import MobileCategorySelector from '../components/Selectors/MobileCategorySelector/MobileCategorySelector'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import Title from '../components/sharedComponents/Title/Title'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import styles from '../page-css/adventures.module.css'
import AdSense from 'react-adsense'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const LodgingContent = ({
  allImageData,
  selectedState,
  onSetCategory,
  onButtonClick,
}) => {
  const matchPoints = MediaQuery(queries)
  const reviews = selectedState
    ? useContext(LodgingContext)
        .filter(review => {
          if (review.categories.find(y => y === selectedState)) {
            return review
          }
        })
        .map((post, index) => {
          const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
          return (
            <BlogPost
              key={index}
              hero={fluidImg}
              post={post}
              currentCategory={selectedState}
              readMore="/lodging"
            />
          )
        })
    : useContext(LodgingContext).map((post, index) => {
        const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
        return (
          <BlogPost
            key={index}
            hero={fluidImg}
            post={post}
            currentCategory={selectedState}
          />
        )
      })
  const uniqueCategories = computeUniqueCategories(
    useContext(LodgingContext)
  ).filter(category => category.value !== 'lodging')
  const title = selectedState ? `${selectedState} Lodging` : 'All Lodging'

  return (
    <div>
      {matchPoints && matchPoints.mobile ? (
        <MobileCategorySelector
          category={selectedState}
          onSetCategory={category => onSetCategory(category)}
          onButtonClick={() => onButtonClick()}
          uniqueCategories={uniqueCategories}
        />
      ) : null}
      {matchPoints && matchPoints.desktop ? <Title title={title} /> : null}
      {reviews.length ? (
        reviews
      ) : (
        <div className={styles.noMatch}>
          Sorry, there are no posts matching the category {`'${selectedState}'`}
        </div>
      )}
    </div>
  )
}

const Lodging = ({ data, location }) => {
  const matchPoints = MediaQuery(queries)
  const myRef = useRef(null)
  const [category, setCategory] = useState(
    location.state ? location.state.category : null
  )
  const onSetCategory = category => {
    setCategory(category)
  }

  const onButtonClick = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  return (
    <>
      <SEO
        title="Lodging Reviews"
        description="Read our lodging reviews or watch our YouTube lodging video tours and reviews."
        url="https://cactusatlas.com/lodging/"
        type="article"
      />
      <div ref={myRef}>
        <LodgingData>
          <TwoColumnLayout>
            <LodgingContent
              allImageData={data}
              selectedState={category}
              onSetCategory={category => onSetCategory(category)}
              onButtonClick={() => onButtonClick()}
            />
            <SideBar>
              <VideoData>
                {category ? (
                  <LodgingVideosByState selectedState={category} />
                ) : (
                  <AllLodgingVideos />
                )}
                <CategorySelector
                  pageType="lodging"
                  currentCategory={category}
                  onSetCategory={category => onSetCategory(category)}
                  onButtonClick={() => onButtonClick()}
                />
                {matchPoints && matchPoints.desktop ? (
                  <AdSense.Google
                    client="ca-pub-1156710604979342"
                    slot="4575037582"
                    style={{
                      display: 'inline-block',
                      width: '300px',
                      height: '250px',
                      position: 'sticky',
                      top: '184px',
                    }}
                  />
                ) : null}
              </VideoData>
            </SideBar>
          </TwoColumnLayout>
        </LodgingData>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
  }
`

LodgingContent.propTypes = {
  selectedState: PropTypes.string,
  allImageData: PropTypes.object,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}

Lodging.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}
export default Lodging
