import React, { useContext } from 'react'
import { getVideosByIds, randomizeVideos } from '../suggestedVideoHelpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeLodgingVideosByState = (
  selectedState,
  allLodgingReviews,
  allVideos
) => {
  const lodgingByState = allLodgingReviews
    .filter(review => {
      if (review.categories.find(y => y === selectedState)) {
        return review
      }
    })
    .map(review => review.videoId)
  return randomizeVideos(getVideosByIds(lodgingByState, allVideos)).slice(0, 3)
}

const LodgingVideosByState = ({ selectedState }) => {
  const allVideos = useContext(VideoContext)
  const allLodgingReviews = useContext(LodgingContext)
  return (
    <SuggestedVideos
      videos={computeLodgingVideosByState(
        selectedState,
        allLodgingReviews,
        allVideos
      )}
    />
  )
}

LodgingVideosByState.propTypes = {
  selectedState: PropTypes.string,
}

export default LodgingVideosByState
